import axios from 'axios';
import qs from 'qs';
import errorCode from '@/const/errorCode'
import Vue from 'vue';
// import router from './../router'
axios.defaults.timeout = 360000;

// axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
axios.defaults.headers.post['Accept'] = '*/*';
// axios.defaults.baseURL = 'http://pigx-gateway:9999';
axios.defaults.baseURL = '';
axios.interceptors.request.use((config) => {
    Vue.prototype.$toast.loading({duration: 0, forbidClick: true});
    let data = config.data;

    if (Vue.prototype.access_token === '') {
        config.headers['Authorization'] = "Basic " + window.btoa('H5:H5');
    } else {
        config.headers['Authorization'] = "Bearer " + Vue.prototype.access_token;
    }
    //设置toKen信息

    config.headers['TENANT-ID'] = Vue.prototype.tenant;

    if (config.method === 'post' && config.headers['Content-Type'] === 'application/x-www-form-urlencoded; charset=UTF-8') {
        config.data = qs.stringify(data);
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((res) =>{
    Vue.prototype.$toast.clear();
    const status = Number(res.status) || 200
    const message = res.data.msg || errorCode[status] || errorCode['default']

    if (status !== 200 || res.data.code === 1) {
        Vue.prototype.$toast.fail(message);
        return Promise.reject(new Error(message))
    }

    Vue.prototype.$toast.clear();
    return res.data;

}, (error) => {
    console.log(error)
    Vue.prototype.$toast.clear();
    Vue.prototype.$toast.fail(
        `错误：${error.response.data.msg}`,
    );
    return Promise.reject(error);
});

export default axios;
