
export default function(num, decimal){
    num = num.toString()
    let index = num.indexOf('.')
    if (index !== -1) {
      num = num.substring(0, decimal + index + 1)
    } else {
      num = num.substring(0)
    }
    let value = parseFloat(num).toFixed(decimal);
    if (isNaN(value)) {value = 0;}
    return value;
  }