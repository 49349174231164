/**
 *
 * @summary 按需引入Vant组件
 * @author LinRenChen
 *
 * Created at     : 2018-06-14 09:28:40
 * Last modified  : 2022-07-04 22:20:53
 */

import {
    Button,
    Tab,
    Tabs,
    Field,
    Radio,
    RadioGroup,
    ActionSheet,
    Sticky,
    Popup,
    SwipeCell,
    Icon,
    List,
    PullRefresh,
    Picker,
    Tabbar,
    TabbarItem,
    Dialog,
    Divider,
    Form,
    Swipe,
    SwipeItem,
    CountDown,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    Row,
    Col,
    Stepper,
    Badge,
    Checkbox,
    CheckboxGroup,
    SubmitBar,
    CellGroup,
    Cascader,
    Cell,
    Switch,
    search,
    IndexBar, IndexAnchor,
    DatetimePicker,
    Popover,
    Uploader,
    Image,
    NoticeBar,

    // ImagePreview
} from 'vant';

// import 'vant/lib/button/style/less';

Image.props.fit = { default: 'cover', type: String }

export default {
    Button,
    Tab,
    Tabs,
    Field,
    ActionSheet,
    Popup,
    SwipeCell,
    Icon,
    List,
    PullRefresh,
    Picker,
    Tabbar,
    TabbarItem,
    Dialog,
    Divider,
    Form,
    Swipe,
    SwipeItem,
    CountDown,
    GoodsAction,
    GoodsActionIcon,
    GoodsActionButton,
    Row,
    Col,
    Stepper,
    Badge,
    Radio,
    RadioGroup,
    Checkbox,
    CheckboxGroup,
    SubmitBar,
    CellGroup,
    Cascader,
    Cell,
    Switch,
    search,
    Sticky,
    IndexBar, IndexAnchor,
    DatetimePicker,
    Popover,
    Uploader,
    Image,
    NoticeBar,

    // ImagePreview
}
