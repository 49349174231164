export default (value) => {
    if (!value) { return 0; }
    let val = Number(value);
    if (isNaN(val)) { return value; }
    let result = val;
    if (val < 1e3) {
        result = {val: val, unit: ''};
    } else if (val >= 1e3 && val < 1e4) {
        result = { val: (val / 1e3), unit: '千' };
    } else if (val >= 1e4 && val < 1e8) {
        result = { val: (val / 1e4), unit: '万' };
    } else if (val >= 1e8 && val < 1e12) {
        result = { val: (val / 1e8), unit: '亿' };
    }
    // if (Fixed) {
    //     return Number(parseFloat(result.val).toFixed(Fixed + 1).slice(0, -1)) + result.unit;
    // } else {
    //     return Number(parseFloat(result.val).toFixed(4).slice(0, -3)) + result.unit;
    // }
    let match = /^(\d+(\.\d{1,2})?)(\d*)$/;
    let ok = result.val.toString().match(match);
    let newValue = ok[1] || ok[0];
    return newValue + result.unit;
};