import formatDecimal from './../lib/fixed';
export default function(value) {
    if (!value) {
        return '0.00';
    }

    let source = formatDecimal(value, 2);
    source = String(source).split(".");
    source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{'+3+'})+$)','ig'),"$1,");
    return source.join(".");
    
}