import Vue from 'vue'
import Vuex from 'vuex'
import $http from '../fetch';
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //前端页面配置
        uiConfig: {},
        //厂家参数配置
        deptSet: {},
        //前端用户信息
        userInfo: {},
        //店铺信息
        shop: {},
        //售后基础配置
        afterSalesSet: {},
        //学生数组
        studentArr:[],
        //购物车数量
        shoppingCartNum:0,
        //当前学生信息
        studentInfo:{},
    },
    getters: {
        getUiConfig(state) {
            return state.uiConfig
        },
        getDeptSet(state) {
            return state.deptSet
        },
        getUserInfo(state) {
            return state.userInfo
        },
        getShop(state) {
            return state.shop
        },
        getAfterSaleSet(state) {
            return state.afterSalesSet
        },
        getStudentArr(state){
            return state.studentArr
        },
        getShoppingCartNum(state){
            return state.shoppingCartNum;
        },
        getStudentInfo(state){
            return state.studentInfo;
        }
    },
    mutations: {
        setUiConfig(state, uiConfig) {
            state.uiConfig = uiConfig
        },
        setDeptSet(state, deptSet) {
            state.deptSet = deptSet;
        },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setShop(state, shop) {
            state.shop = shop
        },
        setAfterSaleSet(state, afterSalesSet) {
            state.afterSalesSet = afterSalesSet
        },
        setStudentArr(state,studentArr){
            state.studentArr = studentArr;
        },
        setShoppingCartNum(state,shoppingCartNum){
            state.shoppingCartNum = shoppingCartNum;
        },
        setStudentInfo(state,studentInfo){
            state.studentInfo = studentInfo;
        },
    },
    actions: {
        //查询基本配置
        queryBasicConfiguration: async function ({commit}, parm) {
            return await new Promise((resolve, reject) => {
                $http.get(`/mall/h5_mall/queryBasicConfiguration/`, {params: parm}, {emulateJSON: true}).then((res) => {
                    if(res.data){
                      let data = res.data;
                      commit('setUiConfig',data.mallUiSet);
                      commit('setDeptSet',data.mallDeptSet);
                      commit('setAfterSaleSet',data.afterSalesSet);
                      commit('setShop',data.shop);
                        resolve();
                    }else{
                        reject();
                    }
                })
            })
        },
        //查询H5用户信息
        queryH5UserInfo: async function({commit}){
            return await  new Promise((resolve,reject)=>{
                $http.get(`/mp/wx_app_user/queryH5UserInfo/`, {params: {}}, {emulateJSON: true}).then((res) => {
                    if(res.data){
                        let userInfo = res.data;
                        commit('setUserInfo',userInfo);
                        resolve();
                    }else{
                        reject();
                    }
                })
            })
        },
        //查询购物车商品数量
        queryShoppingCartNum: async function({commit},param){
            return await new Promise((resolve ,reject)=>{
                $http.get('/mall/h5_mall/queryShoppingCartNum',{params:param},{emulateJSON:true}).then((res)=>{
                    commit('setShoppingCartNum',res.data);
                    if(res.data){
                        resolve();
                    }else{
                        reject();
                    }
                }).catch((err)=>{
                    console.log(err)
                });
            })
        }
    },
    modules: {}
})

