import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import fetch from './fetch';
import withKeepAlive from 'vue-with-keep-alive'
Vue.use(withKeepAlive, router)

import componentConfig from './vantComponent';
import { Toast, Dialog, Step, Steps,Skeleton} from 'vant';
import 'vant/lib/index.css';
Vue.use(Step);
Vue.use(Steps);
Vue.use(Skeleton);
// import 'viewerjs/dist/viewer.css';
Object.keys(componentConfig).forEach(e => {
	Vue.use(componentConfig[e]);
})


import empty from './components/empty';
Vue.component('empty', empty)

// 过滤器
import formatNum from './filter/formatNum';
import dateFormat from './filter/dateFormat';
import money from './filter/money';
import avaterImage from './filter/avaterImage';
Vue.filter('money', money);
Vue.filter('dateFormat', dateFormat);
Vue.filter('formatNum', formatNum);
Vue.filter('Avatar', avaterImage); //默认头像
Vue.prototype.$http = fetch;
Vue.prototype.$toast = Toast;
Vue.prototype.$dialog = Dialog;
//getaway模块url
// let baseUrl = "http://pigx-gateway:9998";
let baseUrl = "";
//设置全局product模块url
Vue.prototype.baseProductUrl = baseUrl+"/product";
//设置全局mall模块url
Vue.prototype.baseMallUrl = baseUrl+"/mall";
//设置全局mp模块url
Vue.prototype.baseMpUrl = baseUrl+"/mp";
//设置全局auth模块url
Vue.prototype.baseAuthUrl = baseUrl+"/auth";
//全局APPID
Vue.prototype.appid = "";
Vue.prototype.appsecret = "";
Vue.prototype.access_token = "";
Vue.prototype.refresh_token = "";
Vue.prototype.userId = "";
Vue.prototype.deptId="";
Vue.prototype.tenant="1579647865129779201";

Vue.config.productionTip = false;
Vue.prototype.px2rem = (val) => {
    // let basicFontSize = document.documentElement.getAttribute('data-size');
    return (val / 37.5) + 'rem';
}

function isWechat() {
    var ua = window.navigator.userAgent.toLowerCase();
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if(ua.match(/MicroMessenger/i) == 'micromessenger'){
        return true;
    }
    return false;
}
// eslint-disable-next-line
let wechat = isWechat();
Vue.prototype.$isWechat = wechat;
// eslint-disable-next-line
// let white = ['/app/login']; //白名单， 不需要登录也可以进入的页面

router.beforeEach(async (to, from, next) => {

	next();
})

new Vue({
	el: '#app',
	router,
	store,
	render: h => h(App)
});
