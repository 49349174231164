/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId') {
    var res = []
    var temp = {}
    for (var i = 0; i < data.length; i++) {
        temp[data[i][id]] = data[i]
    }
    for (var k = 0; k < data.length; k++) {
        if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
            if (!temp[data[k][pid]]['children']) {
                temp[data[k][pid]]['children'] = []
            }
            if (!temp[data[k][pid]]['_level']) {
                temp[data[k][pid]]['_level'] = 1
            }
            data[k]['_level'] = temp[data[k][pid]]._level + 1
            temp[data[k][pid]]['children'].push(data[k])
        } else {
            res.push(data[k])
        }
    }
    return res
}


export const commonMethods = {
    //根据地址获取经纬度
    getLal: (address,cb) => {
        var geocoder = new window.qq.maps.Geocoder();
        geocoder.getLocation(address);
        geocoder.setComplete(function(res) {
            cb(res)
        });
        geocoder.setError(function(err) {
            cb(err)
        });
    }
}



function rad(d) {
    return d * Math.PI / 180.0;
}


// 根据经纬度计算距离，参数分别为第一点的纬度，经度；第二点的纬度，经度
export function getDistances(lat1, lng1, lat2, lng2) {

    var radLat1 = rad(lat1);
    var radLat2 = rad(lat2);
    var a = radLat1 - radLat2;
    var b = rad(lng1) - rad(lng2);
    var s = 2 * Math.asin(Math.sqrt(Math.pow(Math.sin(a / 2), 2) +
        Math.cos(radLat1) * Math.cos(radLat2) * Math.pow(Math.sin(b / 2), 2)));
    s = s * 6378.137; // EARTH_RADIUS;
    // 输出为公里
    s = Math.round(s * 10000) / 10000;

    var distance = s;
    var distance_str = "";

    if (parseInt(distance) >= 1) {
        // distance_str = distance.toFixed(1) + "km";
        distance_str = distance.toFixed(2) + "km";
    } else {
        // distance_str = distance * 1000 + "m";
        distance_str = (distance * 1000).toFixed(2) + "m";
    }

    //s=s.toFixed(4);

    // console.info('距离是', s);
    // console.info('距离是', distance_str);
    // return s;

    //小小修改，这里返回对象
    let objData = {
        distance: distance,
        distance_str: distance_str
    }
    return objData
}

// 定义一些常量
const x_PI = 3.14159265358979324 * 3000.0 / 180.0;


/**
 * 火星坐标系 (GCJ-02) 与百度坐标系 (BD-09) 的转换
 * 即 谷歌、高德 转 百度
 * @param lng
 * @param lat
 * @returns {*[]}
 */
export function gcj02tobd09(lng, lat) {
    var z = Math.sqrt(lng * lng + lat * lat) + 0.00002 * Math.sin(lat * x_PI);
    var theta = Math.atan2(lat, lng) + 0.000003 * Math.cos(lng * x_PI);
    var bd_lng = z * Math.cos(theta) + 0.0065;
    var bd_lat = z * Math.sin(theta) + 0.006;
    return [bd_lng, bd_lat]
}

/**
 * 百度坐标系 (BD-09) 与 火星坐标系 (GCJ-02) 的转换
 * 即 百度 转 谷歌、高德
 * @param bd_lng
 * @param bd_lat
 * @returns {*[]}
 */
export function bd09togcj02(bd_lng, bd_lat) {
    var x = bd_lng - 0.0065;
    var y = bd_lat - 0.006;
    var z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * x_PI);
    var theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * x_PI);
    var gg_lng = z * Math.cos(theta);
    var gg_lat = z * Math.sin(theta);
    return [gg_lng, gg_lat]
}

/**
 * 设置过期时间的缓存对象
 * @param key
 * @param value
 * @param time
 */
export function setInfo (key, value, time) {
    time = time || 12*60*60*60
    const date = new Date().getTime()
    localStorage.setItem(key, JSON.stringify({ value, date, time }))
}
/**
 * 读取
 * @param key
 * @param value
 * @param time
 */
export function getInfo (key) {
    const date = new Date().getTime()
    const item = localStorage.getItem(key) || ''
    if (item) {
        const { value, date: oldDate, time } = JSON.parse(item)
        console.log(date - oldDate, time);
        if (date - oldDate >= time) {
            localStorage.removeItem(key)
            return null
        } else {
            return value
        }
    }
}

/**
 * ios 时间转换
 */
export function transferDate(date) {
    //将xxxx-xx-xx的时间格式，转换为 xxxx/xx/xx的格式
    // return date.replace(/-/g, "/");
    return date;
}

/**
 * 判断是否为空
 */
export function validatenull(val) {
    if (typeof val === 'boolean') {
        return false
    }
    if (typeof val === 'number') {
        return false
    }
    if (val instanceof Array) {
        if (val.length === 0) return true
    } else if (val instanceof Object) {
        if (JSON.stringify(val) === '{}') return true
    } else {
        if (val === 'null' || val == null || val === 'undefined' || val === undefined || val === '') return true
        return false
    }
    return false
}


/**
 * @description: 图片压缩
 * @param {*} img
 * @return {*}
 */
export const compressImg = (img) => {
    let canvas = document.createElement('canvas')
    let ctx = canvas.getContext('2d')
    let tCanvas = document.createElement('canvas')
    let tctx = tCanvas.getContext('2d')
    let width = img.width
    let height = img.height
    //如果图片大于四百万像素，计算压缩比并将大小压至400万以下
    let ratio
    if ((ratio = (width * height) / 4000000) > 1) {
        console.log('大于400万像素')
        ratio = Math.sqrt(ratio)
        width /= ratio
        height /= ratio
    } else {
        ratio = 1
    }
    canvas.width = width
    canvas.height = height
    //    铺底色
    ctx.fillStyle = '#fff'
    ctx.fillRect(0, 0, canvas.width, canvas.height)
    //如果图片像素大于100万则使用瓦片绘制
    let count
    if ((count = (width * height) / 1000000) > 1) {
        console.log('超过100W像素')
        count = ~~(Math.sqrt(count) + 1) //计算要分成多少块瓦片
        // 计算每块瓦片的宽和高
        let nw = ~~(width / count)
        let nh = ~~(height / count)
        tCanvas.width = nw
        tCanvas.height = nh
        for (let i = 0; i < count; i++) {
            for (let j = 0; j < count; j++) {
                tctx.drawImage(
                    img,
                    i * nw * ratio,
                    j * nh * ratio,
                    nw * ratio,
                    nh * ratio,
                    0,
                    0,
                    nw,
                    nh
                )
                ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
            }
        }
    } else {
        ctx.drawImage(img, 0, 0, width, height)
    }
    //进行最小压缩
    let minData = canvas.toDataURL('image/jpeg', 0.1)
    tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0
    return minData
}

// bae64转文件对象
export function dataURLtoFileFun (dataurl, filename) {
    console.log(dataurl, filename)
    // 将base64转换为文件，dataurl为base64字符串，filename为文件名（必须带后缀名，如.jpg,.png）
    let arr = dataurl.split(",");
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}


//判断当前学生信息是否满足学校注册要求
export function isStudentInfo(studentInfo,registerMode) {
    let isStudent = true
    if(registerMode.gradeFlag){
        if(studentInfo.grade == null || studentInfo.grade == ''){
            isStudent = false
        }
    }
    if(registerMode.classnFlag){
        if(studentInfo.classn == null || studentInfo.classn == ''){
            isStudent = false
        }
    }
    if(registerMode.studentFlag){
        if(studentInfo.receiverName == null || studentInfo.receiverName == ''){
            isStudent = false
        }
    }
    if(registerMode.phoneFlag){
        if(studentInfo.receiverPhone == null || studentInfo.receiverPhone == ''){
            isStudent = false
        }
    }
    if(registerMode.sexFlag){
        if(studentInfo.sex == null || studentInfo.sex == ''){
            isStudent = false
        }
    }
    return isStudent
}

/**
 * 姓名脱敏
 * @param name
 * @param replaceChar
 * @returns {string}
 */
export function desensitizeName(name, replaceChar = '*') {
    if (!name) return '';
    let result = '';
    const nameArray = name.split('');
    for (let i = 0; i < nameArray.length; i++) {
        if (i === 0) {
            // 保留第一个字的第一个字母
            result += nameArray[0].charAt(0);
            // 添加其余的符号
            result += replaceChar.repeat(nameArray[0].length - 1);
        } else if (i === nameArray.length - 1 && 2 !== nameArray.length) {
            // 保留最后一个字的全部字母
            result += nameArray[nameArray.length - 1];
        } else {
            // 其余位置用指定符号代替
            result += replaceChar;
        }
    }
    return result;
}

/**
 * 电话号码脱敏
 * @param phoneNumber
 * @returns {*}
 */
export function maskPhoneNumber(phoneNumber) {
    return phoneNumber.replace(/(\d{3})\d{5}(\d{2})/, '$1*****$2');
}
