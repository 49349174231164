<template>
    <div class="empty">
        <img src="@/assets/images/empty.png" alt="">
        <p>{{text}}</p>
    </div>
</template>

<script>
export default {
    props: {
        text: {
            default: '暂无更多内容...',
            type: String,
        },
    },
    name: "Empty"
}

</script>
<style lang="less" scoped>
.empty {
    width: 100%;
    background: #FFFFFF;
    border-radius: 8px;

    padding: 17px 0;
    text-align: center;
    img {
        height: 74px;
        width: 68px;
        margin-bottom: 13px;
    }

    p {
        text-align: center;
        font-size: 11px;
        font-weight: 400;
        color: #999999;
        line-height: 1;
    }
}
</style>